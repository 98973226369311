import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Navigation from "../../components/navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import ReactGA from "react-ga";
import { Link } from "gatsby";
import DonationFooter from "../../components/donation-footer";
import CodeSnippet from "../../components/code-snippet";

class View extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		ReactGA.initialize("UA-115464417-1");
		if (!(window.location.hostname === "localhost")) {
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
	}

	render() {
		return (
			<CodeSnippet
				header={
					"Least Common Multiple | A Helpful Line-by-Line Code Tutorial | Euclidean Algorithm"
				}
				subHeader={""}
				description={""}
				keywords={[]}
				embeddedVideo={"Fw4wzhYIzpc"}
				embeddedCode={
					"https://raw.githubusercontent.com/graphoarty/python-dsa/master/Algorithms/Math/LCMEuclidean.py"
				}
			/>
		);
	}
}

export default View;
